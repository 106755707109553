import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';

export const NotFoundTemplate = () => {
	return (
		<>
			<div className="layouts--section">
				<div className="layouts--container__slim">
					<h1 style={{ textAlign: 'center' }}> 404 - Page not found</h1>
				</div>
			</div>
		</>
	);
};

class NotFound extends React.Component {
	render() {
		return (
			<Layout>
				<Helmet>
					<meta name="title" content="Rockoder" />
					<meta name="description" content="Not found" />
					<title>Rockoder</title>
				</Helmet>
				<NotFoundTemplate />
			</Layout>
		);
	}
}

NotFound.propTypes = {};

export default NotFound;
